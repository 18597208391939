import React from 'react';
import { GridColDef, GridRenderCellParams, GridColumnHeaderParams, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { Tooltip, Link, Checkbox, Button } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { getIcon } from '../../../icons/iconStorage';
import { wrapWithNonce } from '../../../../hooks';
import { GridComponentControlType, GridComponentSourceViewData } from '../../../../store/slices';
import useStyles from './useStyles';
import classNames from 'classnames';

const GridColumns = ({ component, gridValues, sortModel, handleCheckedBoxChange, handleLinkClick, handleButtonClick }: any) => {
    const classes = useStyles();

    const renderCell = (params: GridRenderCellParams) => {
        const gridViewData = params.row[params.field] as GridComponentSourceViewData;
        switch (gridViewData.controlType) {
            case GridComponentControlType.ActionButtons:
                return gridViewData.actionButtons?.map((button, index) => (
                    <Button
                        key={index}
                        disableRipple
                        title={button.toolTip}
                        disabled={button.disabled}
                        classes={{
                            root: classes.gridIconButton,
                        }}
                        onClick={() => handleButtonClick(button)}
                    >
                        {getIcon(button.icon, { color: button.color, fontSize: button.size })}
                    </Button>
                ));
            case GridComponentControlType.CheckBox:
                return (
                    <Checkbox
                        checked={gridValues[params.field]?.[params.id] === 'true'}
                        onChange={(event) => handleCheckedBoxChange(event, params.field, params.id)}
                        className={classes.checkbox}
                    />
                );
            case GridComponentControlType.Link:
                return (
                    <Tooltip title={gridViewData.toolTip ?? gridViewData.value} placement="top">
                        <Link
                            classes={{ root: classes.cellLink }}
                            component="button"
                            onClick={() => handleLinkClick(gridViewData.id, gridViewData.code)}>
                            {gridViewData.value}
                        </Link>
                    </Tooltip>
                );
            case GridComponentControlType.Html:
                return (
                    <Tooltip title={gridViewData.toolTip ?? gridViewData.value} placement="top">
                        <span dangerouslySetInnerHTML={{ __html: wrapWithNonce(gridViewData.html) }} />
                    </Tooltip>
                );
            default:
                return (
                    <span
                        className={classNames({
                            [classes.InvoiceLineItemsNegativeAdjustmentStyle]: gridViewData?.style,
                            [classes.cellText]: !gridViewData?.style,
                        })}
                        title={gridViewData.toolTip}>
                        {gridViewData.value}
                    </span>
                );
        }
    };

    const renderHeader = (params: GridColumnHeaderParams) => {
        const isSortedColumn = sortModel[0]?.field === params.colDef.field;
        const sortDirection = sortModel[0]?.sort;

        return (
            <Tooltip title={GetColumnTooltip(params.colDef, sortModel)}>
                <div className={classes.gridHeaderCell}>
                    <span>{params.colDef.headerName}</span>
                    {isSortedColumn && sortDirection === 'asc' && (
                        <ArrowDropUp fontSize="large" className={classes.sortIconColor} />
                    )}
                    {isSortedColumn && sortDirection === 'desc' && (
                        <ArrowDropDown fontSize="large" className={classes.sortIconColor} />
                    )}
                </div>
            </Tooltip>
        );
    };

    const GetColumnTooltip = (column: GridColDef, sortModel: GridSortModel): JSX.Element => {
        const SORT_BY: string = 'Sort by: ';

        return (
            <div>
                {column.sortable ? `${SORT_BY}${column.headerName}` : column.headerName}
                {sortModel[0] && column.field?.toLowerCase() === sortModel[0].field && (
                    <>
                        <br />
                        Sorted - {sortModel[0].sort === 'asc' ? 'Ascending' : 'Descending'}
                    </>
                )}
            </div>
        );
    };

    const valueGetter = (params: GridValueGetterParams): string | undefined => {
        const { value, link } = (params.row[params.field] as GridComponentSourceViewData) || {};
        return link || value;
    };

    const columns: GridColDef[] = component.columns.map((column: any) => ({
        field: column.code,
        headerName: column.name,
        width: column.widthPercent,
        sortable: column.isSortable,
        renderCell,
        headerClassName: classes.gridHeaderCell,
        renderHeader,
        valueGetter
    }));

    return columns;
};

export default GridColumns;