import { CHSupportedDocumentTypesEnum, ICHQuickFileIndicator } from '@wk/elm-uui-context-handler';
import { uniqueId } from 'lodash';
import { IApplicationUrls, IAppResources, IExternalMenu, IMenuItem } from '../../reducers/types';
import { AppDispatch } from '../../store';
import { openDialog } from '../../store/slices';
import { apiFetch } from '../../utils/fetchUtils';
import { getLogger } from '../../utils/loggingService';
import { clViewQuickFileIcon, isOC } from '../contextLayerService/contextLayerService';
import { IParentItemInfo } from '../listScreen/types';
import { IBaseOperation, TargetScreenType } from './types';

const logger = () => getLogger('t360.screenHelpers');
export interface IDetermineRedirectUrl {
    operation: IBaseOperation;
    applicationUrls: IApplicationUrls;
    parentItemInfo?: IParentItemInfo;
    entityInstanceId?: number;
    selectedRowId?: number;
    entityId?: string;
}

// eslint-disable-next-line max-lines-per-function
export const determineRedirectUrl = ({
    operation,
    applicationUrls,
    parentItemInfo,
    entityInstanceId,
    selectedRowId,
    entityId,
}: IDetermineRedirectUrl): string | undefined => {
    const { isLinkToParent, linkUrl, targetScreenId = 0, targetScreenMode, targetScreenType, url } = operation;

    if (linkUrl) {
        // with a linkUrl prop present, this is a row-operation link
        const link = linkUrl.replace('{selectedRowId}', selectedRowId?.toString() || '');
        return entityId ? `${link}&entityTypeId=${entityId}` : link;
    }

    if (targetScreenMode && targetScreenType) {
        if (targetScreenType === TargetScreenType.List) {
            return applicationUrls.listScreenUrl
                .replace('{screenId}', String(targetScreenId))
                .replace('{mode}', targetScreenMode?.toLowerCase());
        }
        if (targetScreenType === TargetScreenType.Legacy) {
            return applicationUrls.legacyScreenPath
                .replace('{screenId}', String(targetScreenId))
                .replace('{url}', url || '');
        }
        if (targetScreenType === TargetScreenType.View) {
            return applicationUrls.viewScreenPath
                .replace('{screenId}', operation.targetScreenName ?? '')
                .replace('{parameters}', operation.url || '');
        }
        return applicationUrls.itemScreenUrl
            .replace('{screenId}', String(targetScreenId))
            .replace('{mode}', targetScreenMode?.toLowerCase())
            .replace(
                '{entityInstanceId}',
                isLinkToParent && parentItemInfo?.parentInstanceId
                    ? parentItemInfo.parentInstanceId.toString()
                    : entityInstanceId?.toString() || '',
            )
            .replace('{selectedRowId}', selectedRowId?.toString() || '')
            .replace('{parentInstanceId}', !isLinkToParent ? parentItemInfo?.parentInstanceId?.toString() || '' : '')
            .replace('{parentEntityId}', !isLinkToParent ? parentItemInfo?.parentEntityId?.toString() || '' : '')
            .replace('{parentFieldName}', !isLinkToParent ? parentItemInfo?.parentFieldName || '' : '')
            .replace('{parentEntityName}', !isLinkToParent ? parentItemInfo?.parentEntityName?.toString() || '' : '');
    }

    return;
};

export const getCLQuickFileVisibility = (
    isFolder: boolean,
    enableQFDocuments: boolean,
    enableQFEmails: boolean,
): Promise<ICHQuickFileIndicator | undefined> => {
    let retry = false;
    let documentType = CHSupportedDocumentTypesEnum.EMAIL;
    let skipCL = false;

    if (enableQFEmails && enableQFDocuments) {
        retry = true;
    } else if (enableQFEmails) {
        documentType = CHSupportedDocumentTypesEnum.EMAIL;
    } else if (enableQFDocuments) {
        documentType = CHSupportedDocumentTypesEnum.DOCUMENT;
    } else {
        skipCL = true;
    }

    if (!isOC() || skipCL) {
        return Promise.resolve(undefined);
    }

    return clViewQuickFileIcon(isFolder, documentType).then((qfInd) => {
        if (retry && !qfInd.enableQuickFile) {
            const retryDocType =
                documentType === CHSupportedDocumentTypesEnum.EMAIL
                    ? CHSupportedDocumentTypesEnum.DOCUMENT
                    : CHSupportedDocumentTypesEnum.EMAIL;

            return clViewQuickFileIcon(isFolder, retryDocType);
        } else {
            return qfInd;
        }
    });
};

export const showConfirmationDialog = (
    dispatch: AppDispatch,
    confirmationMessage: string,
    appResources: IAppResources,
): Promise<boolean> => {
    return new Promise<boolean>((resolve) => {
        dispatch(
            openDialog({
                dialog: {
                    id: uniqueId('dialog_'),
                    title: appResources.confirmationDialogTitle,
                    message: confirmationMessage,
                    buttons: [
                        { label: appResources.buttonProceed, onClick: () => resolve(true) },
                        { label: appResources.buttonCancel, onClick: () => resolve(false) },
                    ],
                },
            }),
        );
    });
};

export const getExternalMenu = async (subMenuApiKey: string): Promise<IExternalMenu[] | undefined> => {
    const { apiContextRoot, apiContextPath, bffContextRoot } = window.Props as Record<string, string>;
    const apiUrl =
        subMenuApiKey === 'urp' ? `${bffContextRoot}/${subMenuApiKey}/menus` : `${apiContextRoot}${apiContextPath}`;
    const response = await apiFetch<IExternalMenu[]>(apiUrl, undefined, {
        skipTracking: true,
        isExternalServerRequest: true,
    }).catch((error) => {
        logger().error(`Failed to fetch URP submenu : ${error}`);
    });

    if (!response) {
        return;
    }
    return response;
};

export const updateMegaMenu = async (menu: IMenuItem): Promise<IMenuItem> => {
    if (!menu.subMenuApiKey || !menu.isSubMenuExternal) {
        return menu;
    }
    if (menu?.children?.[0]?.children) {
        menu.children[0].children = [];
    }
    const externalMenuList = await getExternalMenu(menu.subMenuApiKey);
    if (!externalMenuList?.length) {
        return { ...menu, name: '', displayValue: '' };
    }
    const menuItems = externalMenuList.flatMap((externalMenu) => {
        const { menuItems } = externalMenu;
        return menuItems.map((menuItem) => {
            const subMenu: IMenuItem = {
                children: [],
                code: menuItem.id.toString(),
                contexts: [],
                description: menuItem.name,
                displayValue: menuItem.name,
                hasCounts: false,
                icon: '',
                id: menuItem.id,
                isDivider: false,
                isNewWindow: false,
                isPopup: false,
                name: menuItem.name,
                passportContexts: [],
                url: menuItem.path,
            };
            return subMenu;
        });
    });

    return { ...menu, children: [...(menu.children?.[0]?.children ?? []), ...menuItems] };
};
