import { URP_GENERIC_ERROR_ROUTE, URP_APP_SERVICE_ERROR_KEY } from '@wk/elm-core-services';
import { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useUUIHistory } from '../../../hooks';
import { ERROR_403_MESSAGE, FAILED_FETCH_MESSAGE } from '../../../utils/fetchUtils';

export const useGlobalErrorHandler = (): void => {
    const errorHandler = useErrorHandler();
    const history = useUUIHistory();
    useEffect(() => {
        const ignoredErrorMessages = [FAILED_FETCH_MESSAGE, ERROR_403_MESSAGE, URP_APP_SERVICE_ERROR_KEY];

        const errorIsHandledByDifferentOverlay = (e: ErrorEvent) => {
            const message = getMessageFromEvent(e);
            return ignoredErrorMessages.find((m) => message?.includes(m) || m.includes(message)) !== undefined;
        };

        const getMessageFromEvent = (e: ErrorEvent) => {
            return e instanceof CustomEvent ? e.detail?.detailedMessage : e?.message;
        };

        const errorEventListener = (e: ErrorEvent) => {
            const isPowerBIElement =
                e.target && (e.target as HTMLElement)?.classList?.contains('powerBIReportContainer');
            console.log('customErrorEventListner', e, isPowerBIElement);

            if (!errorIsHandledByDifferentOverlay(e) && !isPowerBIElement) {
                if (e.message.includes('ResizeObserver loop')) {
                    return;
                }
                errorHandler(e);
            } else if (e?.message?.includes(URP_APP_SERVICE_ERROR_KEY)) {
                history.push(URP_GENERIC_ERROR_ROUTE);
            }
        };

        const unhandledRejectionListener = (e: PromiseRejectionEvent) => {
            const message = getMessageFromEvent(e.reason as ErrorEvent);
            if (!errorIsHandledByDifferentOverlay(e.reason as ErrorEvent)) {
                errorHandler(e.reason);
            } else if (message?.includes(URP_APP_SERVICE_ERROR_KEY)) {
                history.push(URP_GENERIC_ERROR_ROUTE);
            }
        };

        window.addEventListener('error', errorEventListener);
        window.addEventListener('unhandledrejection', unhandledRejectionListener);

        return () => {
            window.removeEventListener('error', errorEventListener);
            window.removeEventListener('unhandledrejection', unhandledRejectionListener);
        };
    }, [errorHandler]);
};
