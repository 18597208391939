import { GridSortModel } from '@mui/x-data-grid-pro';
import React from 'react';
import ReactDOM from 'react-dom';
import {
    Action,
    ConfirmActionPopup,
    ReviewActionType,
} from '../lineItemConfirmActionPopup/confirmActionPopup.component';
import { useConfirmAction } from '../lineItemConfirmActionPopup/hook/useConfirmAction.hook';
import { useModifyAdjustmenthook } from '../modifyAdjustmentActionPopup/hook/modifyAdjustment.hook';
import { ModifyAdjustmentPopup } from '../modifyAdjustmentActionPopup/modifyAdjustment.component';
import { useNoteAction } from '../noteActionPopup/hook/useNoteAction.hook';
import { NoteActionPopup, ButtonAction } from '../noteActionPopup/noteActionPopup.component';
import { UseTreeViewContent } from '../treeView/treeView.component';
import { ViewAndTitleName } from './multiNestingGridView.component';

const useHandlers = (props: any) => {
    const {
        gridValues,
        setGridValues,
        setExpandedRows,
        onAction,
        setData,
        fetchDataAndUpdateStateFilter,
        setSortModel,
        sharedStorage,
    } = props;
    const { saveData, getDescription } = useNoteAction();
    const { adjustData, rejectData, undoAdjustments, getMetaData } = useConfirmAction();
    const { modifyData, getModifyMetaData } = useModifyAdjustmenthook();

    const handleSortChange = React.useCallback(
        (newSortModel: GridSortModel) => {
            if (newSortModel.length <= 0) {
                return;
            }
            setSortModel(newSortModel);
            fetchDataAndUpdateStateFilter(true, newSortModel);
        },
        [sharedStorage],
    );

    const handleCheckedBoxChange = (event: React.ChangeEvent<HTMLInputElement>, code: string, id: string): void => {
        setGridValues(() => {
            const newGridValues = { ...gridValues };
            if (!newGridValues[code]) {
                newGridValues[code] = {};
            }
            if (event.target.checked) {
                newGridValues[code][id] = 'true';
            } else {
                delete newGridValues[code][id];
            }
            return newGridValues;
        });
    };

    const onClick = async (action: string, entityId: string, data?: any): Promise<void> => {
        if (action == ButtonAction.AddNote || action == ButtonAction.EditNote || action == ButtonAction.DeleteNote) {
            await saveData(action, entityId, data);
            updateGrid(entityId, action, data);
        } else {
            let result;
            switch (action) {
                case Action.Adjustment: {
                    result = await adjustData(entityId, data);
                    break;
                }
                case Action.Reject: {
                    result = await rejectData(entityId, data);
                    break;
                }
                case Action.UndoAll:
                case Action.Undo: {
                    result = await undoAdjustments(entityId, action);
                    break;
                }
                case Action.ModifyAdjustment: {
                    result = await modifyData(entityId, data);
                    result = { ...result, description: data.Narrative };
                    break;
                }
            }
            data = { ...data, ...result };
            updateGrid(entityId, action, data);
        }
    };

    const formatAmount = (amount: any, currencySymbol: any) => {
        let formattedAmount = parseFloat(amount.toFixed(2))
            .toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })
            .replace(/-\s*/, '');

        if (amount < 0) {
            return `(${currencySymbol}${formattedAmount})`;
        } else {
            return `${currencySymbol}${formattedAmount}`;
        }
    };

    const actionButtons = (row: any, isDisable: boolean) => {
        const updatedActionButtons = row.action.actionButtons.map((btn: any) => {
            if (btn.action === Action.UndoAll) {
                return {
                    ...btn,
                    disabled: isDisable,
                };
            }
            return btn;
        });
        return updatedActionButtons;
    };

    const updateGrid = (entityId: any, action: string, data?: any) => {
        let updateParentGrid = false;
        let expandCollapse = false;
        let viewNameCount;
        setData((prevRows: any[]) => {
            const updatedRows = prevRows
                .map((row) => {
                    if (row.entityid.value === entityId) {
                        const existingViewName = row?.viewName?.value;
                        if (
                            action === ButtonAction.AddNote ||
                            action === ButtonAction.EditNote ||
                            action === ButtonAction.DeleteNote
                        ) {
                            switch (action) {
                                case ButtonAction.AddNote: {
                                    expandCollapse = true;
                                    return {
                                        ...row,
                                        expand: { value: 'True' },
                                        viewName: {
                                            value: existingViewName?.includes(ViewAndTitleName.Note)
                                                ? existingViewName
                                                : `${existingViewName}${ViewAndTitleName.Note}`,
                                        },
                                    };
                                }
                                case ButtonAction.EditNote: {
                                    return {
                                        ...row,
                                        description: {
                                            value: data,
                                            code: row.description.code,
                                            controlType: row.description.controlType,
                                            id: row.description.id,
                                        },
                                    };
                                }
                                case ButtonAction.DeleteNote: {
                                    return null;
                                }
                            }
                        } else {
                            let negativeAdjustmentStyle;

                            if (data.ReviewerAdjustmentTotal.Amount < 0) {
                                negativeAdjustmentStyle = 'InvoiceLineItemsNegativeAdjustmentStyle';
                            }
                            let reviewerAdjustmentNewValue = formatAmount(
                                data.ReviewerAdjustmentTotal.Amount,
                                data.CurrencySymbol,
                            );

                            let newNetAmount = formatAmount(data.NetAmount.Amount, data.CurrencySymbol);

                            if (action == Action.ModifyAdjustment || action == Action.Undo) {
                                updateParentGrid = true;
                                if (action == Action.Undo) {
                                    return null;
                                }

                                let newAmount = formatAmount(
                                    (data.IsNegative ? -1 : 1) * data.AdjustMentAmount.split(',').join(''),
                                    data.CurrencySymbol,
                                );
                                return {
                                    ...row,
                                    amount: { value: newAmount },
                                    invoice_line_item_description: { value: data.description },
                                };
                            } else if (action == Action.UndoAll) {
                                expandCollapse = true;
                                viewNameCount = row.viewName.value.split(',').filter(Boolean).length;

                                return {
                                    ...row,
                                    action: {
                                        actionButtons: actionButtons(row, true),
                                        controlType: row.action.controlType,
                                    },
                                    expand: { value: viewNameCount > 1 ? 'True' : 'False' },
                                    viewName: { value: row.viewName.value.replace(ViewAndTitleName.Adjustment, '') },
                                    reviewer_adjustment: {
                                        value: reviewerAdjustmentNewValue,
                                        style: negativeAdjustmentStyle,
                                    },
                                    total: { value: newNetAmount },
                                };
                            } else {
                                expandCollapse = true;
                                const isDisable = data.AdjustmentCount == 0 && data.CurrentReviewerAdjustmentCount == 0;
                                return {
                                    ...row,
                                    action: {
                                        actionButtons: actionButtons(row, isDisable),
                                        controlType: row.action.controlType,
                                    },
                                    expand: { value: 'True' },
                                    reviewer_adjustment: {
                                        value: reviewerAdjustmentNewValue,
                                        style: negativeAdjustmentStyle,
                                    },
                                    total: { value: newNetAmount },
                                    viewName: {
                                        value: existingViewName?.includes(ViewAndTitleName.Adjustment)
                                            ? existingViewName
                                            : `${ViewAndTitleName.Adjustment}${existingViewName}`,
                                    },
                                };
                            }
                        }
                    }
                    return row;
                })
                .filter((row) => row !== null);
            if (updatedRows.length <= 0) {
                updateParentGrid = true;
            }
            return updatedRows;
        });

        if (expandCollapse) {
            let isExpand = false;
            setExpandedRows((prevExpandedRows: any[]) => {
                if (prevExpandedRows.includes(entityId)) {
                    isExpand = true;
                }
                const newRows = prevExpandedRows.filter((rowId) => rowId !== entityId);
                return newRows;
            });
            if (data.AdjustmentCount == 0 && data.CurrentReviewerAdjustmentCount == 0 && viewNameCount == 1) {
                isExpand = false;
            }
            if (isExpand) {
                setExpandedRows((prevExpandedRows: any) => {
                    return [...prevExpandedRows, entityId];
                });
            }
        }
        if (updateParentGrid) {
            const extendParams = { action: action };
            modifyParentGrid({ ...data, ...extendParams });
        }
    };

    const modifyParentGrid = (data: any) => {
        onAction && onAction(data);
    };

    const handleButtonClick = async (button: any) => {
        const modalContainer = document.createElement('div');
        document.body.appendChild(modalContainer);

        if (button.action.includes('note')) {
            const data = button.action == ButtonAction.EditNote ? await getDescription(button.payload) : '';

            ReactDOM.render(
                <NoteActionPopup
                    action={button.action}
                    payload={button.payload}
                    modalcontainer={modalContainer}
                    data={data}
                    OkClick={onClick}
                />,
                modalContainer,
            );
        }
        if (button.action == Action.Adjustment || button.action == Action.Reject) {
            let data = (await getMetaData()) as { [key: string]: any };
            data = { ...data, ...button.extendArgs };
            ReactDOM.render(
                <ConfirmActionPopup
                    action={button.action}
                    payload={button.payload}
                    modalcontainer={modalContainer}
                    OkClick={onClick}
                    data={data}
                    ReviewAction={
                        button.action == Action.Adjustment
                            ? ReviewActionType.AdjustLineItem
                            : ReviewActionType.RejectLineItem
                    }
                />,
                modalContainer,
            );
        }
        if (button.action == Action.ModifyAdjustment) {
            let data = (await getModifyMetaData(button.payload)) as { [key: string]: any };
            ReactDOM.render(
                <ModifyAdjustmentPopup
                    action={button.action}
                    payload={button.payload}
                    modalcontainer={modalContainer}
                    OkClick={onClick}
                    data={data}
                />,
                modalContainer,
            );
        }
        if (button.action == Action.UndoAll || button.action == Action.Undo) {
            let confirmMessage;
            if (button.action == Action.UndoAll) {
                confirmMessage = `Are you sure you want to ${button.toolTip.replace(
                    '"Fee"',
                    'this',
                )}?\r\n\r\nOnly your own review actions will be undone.`;
            } else {
                confirmMessage = `Are you sure you want to ${button.toolTip}`;
            }
            const confirmed = window.confirm(confirmMessage);
            if (!confirmed) {
                return;
            }
            let data = (await getModifyMetaData(button.payload)) as { [key: string]: any };
            onClick(button.action, button.payload, data);
        }
        return null;
    };

    const handleLinkClick = async (payload: any, action: any) => {
        if (action == ButtonAction.ViewNote) {
            const data = await getDescription(payload);
            const modalContainer = document.createElement('div');
            document.body.appendChild(modalContainer);
            ReactDOM.render(
                <NoteActionPopup
                    action={action}
                    payload={payload}
                    modalcontainer={modalContainer}
                    data={data}
                    OkClick={onClick}
                />,
                modalContainer,
            );
        }
        return null;
    };

    const handleOnDetailPanelExpandedRowIdsChange = (newExpandedRowIds: any) => {
        const rowids = newExpandedRowIds;
        setExpandedRows(rowids);
    };

    const onActionFromChildGridToUpdateParent = (parentId: any, data?: any) => {
        let isExpand = false;
        setData((prevRows: any[]) => {
            const updatedRows = prevRows.map((row: any) => {
                const viewNames = row.viewName.value.split(',').filter(Boolean);
                if (parentId == row.entityid.value) {
                    if (viewNames.length > 1) {
                        isExpand = true;
                    }
                    if (data.action == Action.ModifyAdjustment || data.action == Action.Undo) {
                        let negativeAdjustmentStyle;

                        if (data.ReviewerAdjustmentTotal.Amount < 0) {
                            negativeAdjustmentStyle = 'InvoiceLineItemsNegativeAdjustmentStyle';
                        }
                        let reviewerAdjustmentNewValue = formatAmount(
                            data.ReviewerAdjustmentTotal.Amount,
                            data.CurrencySymbol,
                        );
                        const isDisable = data.AdjustmentCount == 0 && data.CurrentReviewerAdjustmentCount == 0;
                        let adjustmentCount = data.AdjustmentCount;
                        let newNetAmount = formatAmount(data.NetAmount.Amount, data.CurrencySymbol);
                        return {
                            ...row,
                            reviewer_adjustment: { value: reviewerAdjustmentNewValue, style: negativeAdjustmentStyle },
                            action: {
                                actionButtons: actionButtons(row, isDisable),
                                controlType: row.action.controlType,
                            },
                            total: { value: newNetAmount },
                            viewName: {
                                value:
                                    adjustmentCount > 0
                                        ? row.viewName.value
                                        : row.viewName.value.replace(ViewAndTitleName.Adjustment, ''),
                            },
                            expand: { value: viewNames.length > 1 || adjustmentCount > 0 ? 'True' : 'False' },
                        };
                    } else {
                        return {
                            ...row,
                            expand: { value: viewNames.length > 1 ? 'True' : 'False' },
                            viewName: { value: row.viewName.value.replace(ViewAndTitleName.Note, '') },
                        };
                    }
                }
                return row;
            });
            return updatedRows;
        });

        if (data.action == 'DeleteNote' || (data.AdjustmentCount == 0 && data.CurrentReviewerAdjustmentCount == 0)) {
            setExpandedRows((prevExpandedRows: any[]) => {
                const newRows = prevExpandedRows.filter((rowid) => rowid !== parentId);
                return newRows;
            });
            if (isExpand) {
                setExpandedRows((prevExpandedRows: any) => {
                    return [...prevExpandedRows, parentId];
                });
            }
        }
    };

    const getDetailPanelContent = ({ row }: any) => {
        if (row.expand != undefined && row.expand.value == 'True') {
            return (
                <UseTreeViewContent
                    row={row}
                    onActionFromChildGridToUpdateParent={(data: any) =>
                        onActionFromChildGridToUpdateParent(row.entityid.value, data)
                    }
                />
            );
        }

        return null;
    };

    return {
        handleSortChange,
        handleCheckedBoxChange,
        handleButtonClick,
        handleLinkClick,
        handleOnDetailPanelExpandedRowIdsChange,
        getDetailPanelContent,
    };
};

export default useHandlers;
