import { makeStyles } from '@mui/styles';
import { AppTheme } from '../../../app';

const useStyles = makeStyles<AppTheme>((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        padding: '0px',
        height: '100%',
        marginTop: '0px !important',
        minHeight: '69%',
        maxHeight: '96%',
    },
    grid: {
        flex: 1,
        fontSize: '1rem',
        minHeight: '50px',
        borderWidth: '0px',
        borderTopWidth: '1px',
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        '& .MuiDataGrid-row': {
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
        '& .MuiDataGrid-iconSeparator': {
            opacity: 0,
        },
        '& .MuiDataGrid-row:hover': {
            backgroundColor: 'transparent',
        },
        '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none', // Removes the focus outline
        },
        '& .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none', // Removes the focus outline when interacting with children
        },
        '& .MuiDataGrid-columnHeaderCheckbox': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 0,
        },
    },
    gridHeaderCell: {
        display: 'flex',
        fontSize: '1rem',
        fontWeight: 500,
    },
    HighLightRow: {
        backgroundColor: '#FFFF88 !important',
    },
    cellLink: {
        ...theme.styles.ellipsis,
        fontSize: 'inherit',
    },
    cellText: {
        ...theme.styles.ellipsis,
        color: '#474747',
    },
    sort: {
        '$grid .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeader--sorted):hover &': {
            opacity: '1',
            fill: 'none',
        },
    },
    sortDesc: {
        transform: 'rotate(180deg)',
    },
    pagesLabel: {
        textAlign: 'right',
        marginLeft: 'auto',
        marginRight: '16px',
    },
    gridButtonsArea: {
        textAlign: 'right',
        alignContent: 'right',
        float: 'right',
        marginLeft: 'auto',
    },
    gridIconButton: {
        padding: theme.spacing(0.75),
        color: theme.palette.primary.dark,
        minWidth: 'auto',
    },
    gridButton: {
        marginRight: '16px !important;',
        marginBottom: '16px !important;',
        lineHeight: '20px',
        boxShadow: 'none',
    },
    gridArea: {
        display: 'flex',
        flexDirection: 'row',
    },
    gridPager: {
        '& .MuiPaginationItem-text': {
            fontSize: '0.85rem',
            color: theme.palette.primary.dark,
        },
        '& .MuiPaginationItem-root': {
            minWidth: '16px',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
            backgroundColor: '#FFFFFF',
            color: 'black',
            fontWeight: 'bold',
        },
    },
    checkbox: {
        color: theme.palette.secondary.main,
        stroke: theme.palette.background.paper,
        strokeWidth: 1,
    },
    checkboxChecked: {
        color: `${theme.palette.action.active} !important`,
    },
    InvoiceLineItemsNegativeAdjustmentStyle: {
        ...theme.styles.ellipsis,
        color: '#CC0000 !important',
    },
    hideSortIcon: {
        '& .MuiDataGrid-sortIcon': {
            display: 'none',
        },
    },
    sortIconColor: {
        color: '#003d61',
    },
}));

export default useStyles;